import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getAuth, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { Capacitor } from '@capacitor/core'

// intitalize firebase
const config = {
  apiKey: 'AIzaSyCpZataVT9bf5edexyq9bpGn-lAel4OSE4',
  authDomain: 'formfactories-incept3d.firebaseapp.com',
  databaseURL: 'https://formfactories-incept3d.firebaseio.com',
  projectId: 'formfactories-incept3d',
  storageBucket: 'formfactories-incept3d.appspot.com',
  messagingSenderId: '19356599543',
  measurementId: 'G-NRKD95QPY1',
  experimentalAutoDetectLongPolling: true,
}
const firebaseApp = initializeApp(config)

// firebase utils
const db = getFirestore(firebaseApp)
const auth = Capacitor.isNativePlatform() ? initializeAuth(firebaseApp, {
  persistence: indexedDBLocalPersistence,
}) : getAuth()
const storage = getStorage(firebaseApp)

export { db, auth, storage }
